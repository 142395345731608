import React from "react";

function BlogTitleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="29"
      fill="none"
      viewBox="0 0 31 29"
      {...props}
    >
      <path
        fill="#fff"
        d="M15.485 14.622a.934.934 0 01-.934-.934v-.934H5.836v.934a.934.934 0 01-1.867 0V11.82c0-.515.418-.933.933-.933h10.583c.515 0 .934.418.934.933v1.868a.934.934 0 01-.934.934z"
        {...props}
      ></path>
      <path
        fill="#fff"
        d="M10.194 23.025a.934.934 0 01-.934-.934v-9.96a.934.934 0 011.867 0v9.96a.934.934 0 01-.934.934z"
        {...props}
      ></path>
      <path
        fill="#fff"
        d="M11.751 23.336H8.64a.934.934 0 010-1.867h3.112a.934.934 0 010 1.867zM25.444 12.754h-5.602a.934.934 0 010-1.867h5.602a.934.934 0 010 1.867zM25.444 17.735h-5.602a.934.934 0 010-1.868h5.602a.934.934 0 010 1.868zM25.444 22.714h-5.602a.934.934 0 010-1.867h5.602a.934.934 0 010 1.867z"
        {...props}
      ></path>
      <path
        fill="#fff"
        d="M26.69 28.317H3.659a3.427 3.427 0 01-3.424-3.424V4.35A3.427 3.427 0 013.658.928h23.033a3.427 3.427 0 013.423 3.424v20.542a3.426 3.426 0 01-3.423 3.424zM3.659 2.794c-.858 0-1.556.699-1.556 1.557v20.542c0 .858.698 1.556 1.556 1.556h23.033c.857 0 1.556-.698 1.556-1.556V4.35c0-.857-.699-1.556-1.556-1.556H3.658z"
        {...props}
      ></path>
      <path
        fill="#fff"
        d="M29.18 7.152H1.169a.934.934 0 010-1.868h28.013a.934.934 0 010 1.868z"
        {...props}
      ></path>
    </svg>
  );
}

export default BlogTitleIcon;
